import Layout from "components/layout";
import IndustryComp from "components/translationIndustry";
import React from "react";
import SEO from "components/seo";

function index() {
  const title = "Tomedes Translation Industry";
  const description =
    "The hottest and the latest translation industry news – Tomedes shares everything you need to know form inside the translation industry.";
  const keywords = "translation, translation service, document translation";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/translation-industry.php"
      />
      <IndustryComp />
    </Layout>
  );
}

export default index;
