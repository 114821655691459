import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, useStaticQuery, graphql } from "gatsby";
import QuotePopUp from "components/popup/quotePopUp/index";

export const usePageQuery = () => {
  const industryData = useStaticQuery(graphql`
    query translationIndustryQuery {
      allTranslationIndustry(sort: { fields: data___seo_project_create_date, order: DESC }) {
        edges {
          node {
            data {
              seo_h1
              slug
              seo_project_meta_desc
              seo_project_create_date
            }
          }
        }
      }
    }
  `);
  return industryData;
};

const TranslationIndustry = () => {
  const [open, setOpen] = useState(false);
  const data = usePageQuery();

  return (
    <div className="text-white font-primary text-2xl h-full">
      <div className="bannerImage bg-industry-bg-image bg-no-repeat bg-cover bg-center h-460 2xl:h-560">
        <div className=" relative top-24 2xl:top-56  ml-0 pl-4 xl:ml-16 2xl:ml-416 laptop:ml-80">
          <div className=" w-full  max-w-max h-4 sm:h-4 md:h-8 mb-32">
            <h1 className="text-3xl lg:text-6xl bg-contact-img bg-no-repeat inline-block custom-bg-pos font-medium w-full font-riot custom-bg-h1">
              Translation Industry News
            </h1>
          </div>
          <div className="mt-12">
            <div className="grid grid-cols-1 py-6 space-y-6 text-center md:flex md:flex-row md:align-baseline md:items-center md:space-x-6 md:space-y-0 font-opensans">
              <Link
                to="/quote"
                className="fr-btn1 uppercase rounded-full py-3 bg-orange text-white text-sm cursor-pointer w-60  font-semibold font-primary"
              >
                Translate Now
              </Link>
              <a
                onClick={() => setOpen(true)}
                className="fr-btn2 uppercase rounded-full bg-white py-3 text-black text-sm  cursor-pointer w-60 font-semibold font-primary"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
      <QuotePopUp open={open} setOpen={setOpen} />
      <div className="w-9/12 mx-auto font-primary font-light p-6">
        <div className="py-3 w-full">
          {data.allTranslationIndustry.edges.map(({ node }) => {
            return (
              <ul>
                <li className="mb-6 py-6 grid border-b-2 border-newBorder">
                  <Link to={node.data.slug} className="text-lightBlue text-2xl font-normal">
                    {node.data.seo_h1}
                  </Link>
                  <span className="mt-2 text-base font-normal text-black">
                    {moment(node.data.seo_project_create_date).format("MMMM DD, YYYY")}
                  </span>
                  <span className="mt-3 text-lg text-black font-normal">{node.data.seo_project_meta_desc}</span>
                  <Link to={node.data.slug} className="text-lightBlue mt-3 text-sm font-bold">
                    Read More
                  </Link>
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TranslationIndustry;
